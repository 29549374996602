import { WDHFlexspotPardotForm } from "./../../../../wdh-feature/wdh-flexspot-pardot-form";
import { ComponentBootstrap } from "@demant/react-bootstrap";
import flexSpotSchema from "@demant/react-bootstrap/lib/schemas/flex-spot";
DGS.OnLoad.getInstance().register(function () {
  var myComponent = new ComponentBootstrap(".flex-spot.pardot-form");
  myComponent.schemaData = flexSpotSchema;
  myComponent.reactApp = WDHFlexspotPardotForm;
  myComponent.run();

  //make brevo form responsive
  var checkAndSetIframeHeight = function checkAndSetIframeHeight() {
    var container = document.querySelector(".flex-spot.pardot-form");
    var iframe = document.querySelector(".element__item__iframe");
    if (container !== null && container !== void 0 && container.classList.contains("pardot-form") && iframe) {
      var screenWidth = window.innerWidth;
      var newHeight;
      if (screenWidth > 712) {
        newHeight = "400px";
      } else if (screenWidth > 463) {
        newHeight = "450px";
      } else if (screenWidth > 420) {
        newHeight = "500px";
      } else if (screenWidth > 324) {
        newHeight = "550px";
      } else {
        newHeight = "600px";
      }
      iframe.style.height = newHeight;
    }
  };
  checkAndSetIframeHeight();
  window.addEventListener("resize", checkAndSetIframeHeight);
});